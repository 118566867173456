<template>
  <div class="card" :id="id + '_card'" ref="cardTop">
    <div class="card-header">
      <h2 class="mb-0">
        <button
          :id="id + '_faq'"
          class="btn btn-link"
          v-bind:class="[this.accordionActive ? 'active' : 'not-active']"
          @click="accordionToggle()"
          type="button"
          data-toggle="collapse"
          :data-target="accordionTarget"
          :aria-expanded="accordionActive"
          :aria-controls="accordionName"
        >
          <span>{{ title }}</span>
        </button>
      </h2>
    </div>
    <div
      class="card-content"
      :id="accordionName"
      v-show="this.accordionActive"
      :aria-labelledby="accordionName"
      :data-parent="accordionName"
    >
      <div class="card-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
const uniqueId = require('lodash.uniqueid');

export default {
  name: 'accordion',
  props: {
    msg: String,
    title: { required: true },
    active: { default: false },
    question: String,
    questionTarget: String,
  },
  data() {
    return {
      accordionActive: false,
      id: '',
    };
  },

  computed: {
    accordionName() {
      return this.title.toLowerCase().replace(/ /g, '-');
    },
    accordionTarget() {
      return '#' + this.title.toLowerCase().replace(/ /g, '-');
    },
  },
  methods: {
    assignPropsToData() {
      this.accordionActive = this.active;
    },
    accordionToggle() {
      this.accordionActive = !this.accordionActive;
    },
  },
  mounted() {
    this.id = uniqueId();
    const question = this.$route.query.question;
    const secondQuestion = this.$route.query.q2;

    this.$nextTick(() => {
      if (secondQuestion && this.questionTarget) {
        if (secondQuestion === this.questionTarget) {
          this.accordionActive = true;
        }
      }

      if (question && this.questionTarget) {
        if (question === this.questionTarget) {
          // console.log('Opening accordion target: ' + this.questionTarget);
          this.accordionActive = true;
          const context = this;
          setTimeout(function () {
            context.$refs.cardTop.scrollIntoView({ behavior: 'smooth' });
          }, 1000);
        }
      }
    });
  },

  created() {
    this.assignPropsToData();
  },
};
</script>
