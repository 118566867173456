<template>
  <div class="learn-about-process">
    <HeaderNav />
    <main id="content" role="main" tabindex="-1">
      <div
        class="container-fluid learn-process-banner"
        role="presentation"
      ></div>

      <!--START CONTAINER ICONS-->
      <div class="container py-5">
        <div class="row">
          <div class="col-12">
            <h1 id="main-title" class="innerpage my-5">
              What is the Merck U.S. Patient Advocacy (USPA) Grant process?
            </h1>
            <p>
              This describes the basic process to apply for and receive a Merck
              USPA Patient Advocacy or Fundraising Charitable Grant.
            </p>
          </div>
        </div>
        <div class="row px-3 mt-5">
          <div class="col-2 icon-col">
            <img
              alt=""
              role="presentation"
              src="@/assets/images/icon-doc-check.png"
              class="img-fluid"
            />
          </div>
          <div class="col-10 grant-process-step p-5">
            <p class="step">
              <span class="step-num">1</span>See if you are eligible
            </p>
            <p>
              Review our
              <router-link
                class="content-link tablink-eligibility"
                :to="{
                  name: 'faqs',
                  query: { question: 'ecpag', tab: 'eligibility', q2: 'ecfcg' },
                }"
                target="_blank"
                >eligibility requirements</router-link
              >
              and
              <router-link
                class="content-link"
                :to="{
                  name: 'faqs',
                }"
                target="_blank"
                >FAQs</router-link
              >
              to make sure your organization and program or event are eligible
              for support.
            </p>
          </div>
        </div>
        <div class="row px-3 mt-4">
          <div class="col-2 icon-col">
            <img
              alt=""
              role="presentation"
              src="@/assets/images/icon-doc-check2.png"
              class="img-fluid"
            />
          </div>
          <div class="col-10 grant-process-step p-5">
            <p class="step">
              <span class="step-num">2</span>Prepare for your submission
            </p>
            <p>
              Before you start the online application, review our
              <router-link
                class="content-link tablink-prepare"
                :to="{
                  name: 'faqs',
                  query: { tab: 'submissions' },
                }"
                target="_blank"
                >submission requirements</router-link
              >
              and
              <router-link
                class="content-link tablink-prepare"
                :to="{
                  name: 'faqs',
                  query: { tab: 'review' },
                }"
                target="_blank"
                >review and approval process</router-link
              >.
            </p>
          </div>
        </div>
        <div class="row px-3 mt-4">
          <div class="col-2 icon-col">
            <img
              alt=""
              role="presentation"
              src="@/assets/images/icon-touch.png"
              class="img-fluid"
            />
          </div>
          <div class="col-10 grant-process-step p-5">
            <p class="step">
              <span class="step-num">3</span>Log in to the MSD grant portal (
              <a
                href="http://www.msdgrant.com"
                class="content-link interstitial-link"
                data-bs-toggle="modal"
                data-bs-target="#interstitial-modal"
                target="_blank"
                >www.msdgrant.com</a
              >) and apply
            </p>
            <p>
              Merck Sharp &amp; Dohme (MSD), known as Merck &amp; Co., Inc. in
              the U.S. and Canada, offers support through a variety of grant and
              charitable contribution programs, each of which has its own focus
              and application process.
            </p>
            <ul class="minus-plus-bullets">
              <li>
                Visit msdgrant.com and create an account, or log in to an
                existing account
              </li>
              <li>
                Fill out and submit the application
                <ul>
                  <!--<li>
                    See our
                    <router-link
                      class="content-link tablink-prepare"
                      :to="{
                        name: 'faqs',
                        query: { tab: 'submissions' },
                      }"
                      target="_blank"
                      >submission tutorial</router-link
                    >
                    to learn more
                  </li>-->
                  <li>
                    If you start the online application and need to pause, you
                    can save your application and return at any time – but you
                    must submit the application at least
                    <strong>60 days before</strong> your activity or event will
                    take place
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="row px-3 mt-4">
          <div class="col-2 icon-col">
            <img
              alt=""
              role="presentation"
              src="@/assets/images/icon-stamp.png"
              class="img-fluid"
            />
          </div>
          <div class="col-10 grant-process-step p-5">
            <p class="step">
              <span class="step-num">4</span>USPA will review your application
            </p>
            <p>
              We will review your completed application and give you a decision
              as soon as possible via email.
            </p>
          </div>
        </div>
        <div class="row px-3 mt-4">
          <div class="col-2 icon-col">
            <img
              alt=""
              role="presentation"
              src="@/assets/images/icon-doc-sign.png"
              class="img-fluid"
            />
          </div>
          <div class="col-10 grant-process-step p-5">
            <p class="step">
              <span class="step-num">5</span>If we decide to support your
              request, we will send you a Letter of Agreement (LOA) to sign.
              Your grant request will be considered approved once the LOA has
              been signed.
            </p>
            <p>
              The LOA must be signed by your organization’s authorized signer
              (as designated in your application) before the start date of your
              program or event.
            </p>
          </div>
        </div>
        <div class="row px-3 mt-4">
          <div class="col-2 icon-col">
            <img
              alt=""
              role="presentation"
              src="@/assets/images/icon-money-hand.png"
              class="img-fluid"
            />
          </div>
          <div class="col-10 grant-process-step p-5">
            <p class="step">
              <span class="step-num">6</span>Once you sign the LOA, we will send
              payment.
            </p>
            <p>
              Payment is expected to be sent electronically to the bank account
              listed in your application approximately
              <strong>2-6 weeks</strong> after the payment milestone date in the
              LOA.
            </p>
          </div>
        </div>
        <div class="row px-3 mt-4">
          <div class="col-2 icon-col">
            <img
              alt=""
              role="presentation"
              src="@/assets/images/icon-carry.png"
              class="img-fluid"
            />
          </div>
          <div class="col-10 grant-process-step p-5">
            <p class="step">
              <span class="step-num">7</span>Carry out your activities, then
              report back to us
            </p>
            <p>
              Once your program is completed, we will request that you complete
              a reconciliation form and an outcomes form through our grant
              portal within 45 calendar days. These are only required for
              Patient Advocacy educational programs. These are not required for
              Fundraising Charitable events.
            </p>
          </div>
        </div>
      </div>
      <!--END CONTAINER ICONS-->

      <!--START CONTAINER-->
      <div class="container py-5">
        <div class="row">
          <div class="col-12">
            <h2>Checklist for applying for a Merck USPA grant</h2>
            <p>
              Before you begin your application, please review the following
              checklist suggestions.
            </p>
          </div>
        </div>
      </div>
      <!--END CONTAINER-->
      <!--START CONTAINER-->
      <div class="container applying_accordion px-0">
        <div class="row">
          <div class="col-lg-6 col-md-7">
            <accordions>
              <accordion title="General guidelines">
                <p>As you apply...</p>
                <ul>
                  <li>
                    You must create an account in the grant management system to
                    submit your application
                  </li>
                  <li>
                    You must submit at least 60 days in advance of the program
                    or event you are requesting funding for
                  </li>
                  <li>
                    If you start filling out the application then need time to
                    complete it, you can save it and come back at any time –
                    just make sure to submit it at least 60 days before your
                    program or event takes place
                  </li>
                </ul>
                <p>While we review your application...</p>
                <ul>
                  <li>
                    You may manage and check the status of requests when logged
                    in
                  </li>
                  <li>
                    We may ask you for more information – send it right away
                    because we cannot proceed with your request until we receive
                    it
                  </li>
                </ul>

                <p>If we decide to give grant funding to you...</p>
                <ul>
                  <li>
                    You will get a Letter of Agreement (LOA) that must be signed
                    before your program or event start date
                  </li>
                  <li>
                    Payment is expected to be sent electronically approximately
                    2-6 weeks after the payment milestone date on the LOA
                  </li>
                </ul>
              </accordion>
              <accordion title="Helpful hints for before you log in to apply">
                <ul>
                  <li>
                    For the best results, you may want to use Google Chrome as
                    your browser when you apply
                  </li>
                  <li>
                    Read our FAQs to see if your organization and program or
                    event are eligible for support and find answers to the most
                    common grant questions
                  </li>
                  <li>
                    Prepare a summary of your program or event that includes
                    specifics on how you will use the funds and how you will
                    evaluate and communicate the program outcomes. Keep it
                    concise
                  </li>
                  <li>
                    Have your required documents prepared and ready to upload
                  </li>
                </ul>
              </accordion>
            </accordions>
          </div>

          <div class="col-lg-6 col-md-5 ps-lg-5">
            <img
              alt=""
              role="presentation"
              src="@/assets/images/review-banner.jpg"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
      <!--END CONTAINER-->
      <!--START CONTAINER-->
      <div class="container py-5 my-3 bg-ltgray p-5">
        <h2 class="text-center">Apply at the MSD grant portal</h2>
        <h3 class="text-center my-4">
          Once your prep work is completed, you are ready to submit an
          application
        </h3>
        <div class="container w-75 mx-auto">
          <a
            class="interstitial-link arrow-btn lgtext bg-teal my-5"
            href="http://msdgrant.com/"
            data-bs-toggle="modal"
            data-bs-target="#interstitial-modal"
            target="_blank"
            ><span>Merck grant submission tool</span></a
          >
        </div>
      </div>
      <!--END CONTAINER-->
      <!--START CONTAINER-->
      <div class="container py-5 my-3">
        <HelpfulLinks />
      </div>
      <!--END CONTAINER-->
    </main>
    <!-- <clicktoTabs /> -->
    <LeavingModal />
    <Footer />
  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue';
import Footer from '@/components/Footer.vue';
import HelpfulLinks from '@/components/HelpfulLinks.vue';
import Accordions from '@/components/Accordions.vue';
import accordion from '@/components/AccordionItem.vue';
import LeavingModal from '@/components/LeavingModal.vue';

export default {
  name: 'grant-types',
  components: {
    HeaderNav,
    Footer,
    HelpfulLinks,
    accordion,
    Accordions,
    LeavingModal,
  },
};
</script>
